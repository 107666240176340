import React, { useEffect, useState, useMemo } from "react";
import * as store from "../../framework/customStore";
import { useAppSelector } from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as workTimeService from "../../services/workTimeBetaService";
import * as dayBookingService from "../../services/dayBookingService";
import * as baseService from "../../services/baseService";
import { WorkTimeList } from "./workTimeList";
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import * as workHoursService from "../../services/workHoursService";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";
import { useReload } from "../../hooks";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { WorkTimeBottomBar } from "./workTimeBottomBar";
import {
    getWorkShiftTimeSlotItems,
    getWorkTimeTypes,
} from "../../services/workTimeBetaService";
import {
    WorkShiftTimeSlotItem,
    getDutyEndingWorktimes,
    getDutyStartingWorktimes,
    getDutyWorkShiftTimeSlots,
} from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { Base } from "../../framework/base";
import * as workTimeBetaService from "../../services/workTimeBetaService";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { Dayjs } from "dayjs";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { getDayBookingTypes } from "../../services/dayBookingTypeService";
import { useFetch } from "../../hooks/useFetch";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { getWorkShiftsNew } from "../../services/workShiftService";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { HourBookingCategory, IWorkHoursItem } from "../../models/workTime/workHoursItem";
import {
    WorkHourSaveData,
    getWorkHourSaveItem
} from "../../models/workHour/workHourSaveData";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { getSalaryRowTypeItems } from "../../services/salaryRowTypeService";
import { t } from "i18next";
import { customHistory } from "../../framework/customHistory";
import {
    WorkTimeListCategoryColumns,
    WorkTimeListColumn,
    WorkTimeListColumnGroup,
    WorkTimeListGroupedColumns,
    getAbsenceSalaryRowTypeKindCodes,
} from "../../models/workTime/workTimeList";
import { StrMeasureUnit } from "../../models/common/enums";
import { EmployeeListItemDto } from "../../models/employee/employee";
import MuiRadioGroup from "../framework/muiRadioGroup";

export interface IWorkTimeListProp {
    employees: EmployeeListItemDto[]; // Will be needed here and elsewhere
    path: string; // Is needed by child
}

function GetEmployeeIds(
    selectedEmployees: string[] | null,
    allEmployees: EmployeeListItemDto[]
) {
    return allEmployees
        ?.filter(
            (e) =>
                !selectedEmployees ||
                selectedEmployees.length === 0 ||
                selectedEmployees.includes(e.id)
        )
        .map((e) => e.id);
}

interface EmployeeWorkHours {
    employeeIds: string[];
    workHours: IWorkHoursItem[];
}

enum DisplayState {
    Saved = "saved",
    Calculated = "calculated",
}

export function WorkTimeListBase(props: IWorkTimeListProp) {
    const [savedEmployeeWorkHours, setSavedEmployeeWorkHours] =
        useState<EmployeeWorkHours>();
    const [calculatedEmployeeWorkHours, setCalculatedEmployeeWorkHours] =
        useState<EmployeeWorkHours>();

    const { filters, fetchFilters } = useAppSelector(
        (state) => state.workTimeBeta
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [workShifts, setWorkShifts] = useState<WorkShiftDto[]>();
    const [dayBookingItems, setDayBookingItems] =
        useState<DayBookingItemBeta[]>();
    const [dayBookingTypes, setDayBookingTypes] =
        useState<DayBookingTypeItemBeta[]>();
    const [workTimeTypes, setWorkTimeTypes] = useState<WorkTimeType[]>();
    const [salaryRowTypes, setSalaryRowTypes] = useState<SalaryRowTypeItem[]>();
    const [columns, setColumns] = useState<WorkTimeListGroupedColumns>();
    const [reloadKey, reload] = useReload();
    const [savingSalaries, setSavingSalaries] = useState(false);
    const [saveDialogState, setSaveDialogState] = useState<
        "closed" | "open" | "saved"
    >("closed");
    const [displayState, setDisplayState] = useState(DisplayState.Saved);

    const showSavedHours = displayState === DisplayState.Saved;
    const workHours = (
        showSavedHours ? savedEmployeeWorkHours : calculatedEmployeeWorkHours
    )?.workHours;

    const employeeIds = useMemo(
        () =>
            filters.selectedEmployeeGroup
                ? GetEmployeeIds(filters.selectedEmployees, props.employees)
                : [],
        [
            filters.selectedEmployees,
            filters.selectedEmployeeGroup,
            props.employees,
        ]
    );
    const filteredEmployees = useMemo(
        () => props.employees?.filter((e) => employeeIds.includes(e.id)) ?? [],
        [employeeIds, props.employees]
    );

    const selectedCostCenters = filters.selectedCostCenters?.map((c) => c?.id);
    const shouldFetch =
        fetchFilters?.initialized && !!filters.selectedEmployeeGroup && props.employees?.length > 0;

    const loadWorkShifts = async(signal?: AbortSignal) => {
        try {
            const result = await getWorkShiftsNew(
                props.employees?.map((e) => e.id) ?? [],
                Base.dayjsToJsonDate(
                    fetchFilters.selectedSalaryPeriod.startDate
                ),
                Base.dayjsToJsonDate(fetchFilters.selectedSalaryPeriod.endDate),
                true,
                signal
            );
            setWorkShifts(Base.getTypedArray(result.data, WorkShiftDto));
        } catch (error) {
            if (!signal?.aborted) {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(
                        baseService.getErrorMessageFromError(error)
                    )
                );
            }
        }
    };

    const loadWorkHours = (
        saved: boolean,
        preserve: boolean,
        signal?: AbortSignal
    ) => {
        store.customStore.dispatch(storeActions.fetchStart());

        return (
            saved
                ? workHoursService.getSavedWorkHoursNew
                : workHoursService.getCalculatedWorkHoursNew
        )(
            filters.selectedEmployeeGroup.id,
            employeeIds,
            Base.dayjsToJsonDate(fetchFilters.selectedSalaryPeriod.startDate),
            Base.dayjsToJsonDate(fetchFilters.selectedSalaryPeriod.endDate),
            true,
            signal
        )
            .then((result) => {
                if (saved) {
                    // Preserve previously loaded employee workHours to avoid having to reload
                    setSavedEmployeeWorkHours({
                        employeeIds: Base.getUniqueStringItems([
                            ...(preserve
                                ? savedEmployeeWorkHours?.employeeIds ?? []
                                : []),
                            ...employeeIds,
                        ]),
                        workHours: [
                            ...(preserve
                                ? savedEmployeeWorkHours?.workHours.filter(
                                      (h) => !employeeIds.includes(h.employeeId)
                                  ) ?? []
                                : []),
                            ...result?.data ?? [],
                        ],
                    });

                    if (!preserve) {
                        setCalculatedEmployeeWorkHours({
                            employeeIds: [],
                            workHours: [],
                        });
                    }
                } else {
                    // Preserve previously loaded employee workHours to avoid having to reload
                    setCalculatedEmployeeWorkHours({
                        employeeIds: Base.getUniqueStringItems([
                            ...(preserve
                                ? calculatedEmployeeWorkHours?.employeeIds ?? []
                                : []),
                            ...employeeIds,
                        ]),
                        workHours: [
                            ...(preserve
                                ? calculatedEmployeeWorkHours?.workHours.filter(
                                      (h) => !employeeIds.includes(h.employeeId)
                                  ) ?? []
                                : []),
                            ...result?.data ?? [],
                        ],
                    });

                    if (!preserve) {
                        setSavedEmployeeWorkHours({
                            employeeIds: [],
                            workHours: [],
                        });
                    }
                }
            })
            .catch((error) => {
                if (!signal?.aborted) {
                    store.customStore.dispatch(
                        storeActions.showErrorMessage(
                            baseService.getErrorMessageFromError(error)
                        )
                    );
                }
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    const loadDayBookings = async(signal?: AbortSignal) => {
        store.customStore.dispatch(storeActions.fetchStart());
        try {
            const startDate = Base.dayjsToJsonDate(fetchFilters.selectedSalaryPeriod.startDate);
            const endDate = Base.dayjsToJsonDate(fetchFilters.selectedSalaryPeriod.endDate);
            const employeeIds = props.employees?.map((employee) => employee.id) ?? [];
            const dayBookings = await dayBookingService.getDayBookingItems(startDate, endDate, employeeIds, signal);
            setDayBookingItems(dayBookings);
        } catch (error) {
            if (!signal?.aborted) {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(baseService.getErrorMessageFromError(error))
                );
            }
        } finally {
            store.customStore.dispatch(storeActions.fetchEnd());
        }
    };

    useEffect(() => {
        if (
            shouldFetch &&
            (savedEmployeeWorkHours || calculatedEmployeeWorkHours) &&
            // savedEmployeeWorkHours &&
            // Only fetch if employee is not already fetched.
            employeeIds?.some(
                (id) =>
                    !(
                        showSavedHours
                            ? savedEmployeeWorkHours
                            : calculatedEmployeeWorkHours
                    )?.employeeIds.includes(id)
            )
        ) {
            void loadWorkHours(showSavedHours, true);
        }
    }, [employeeIds, showSavedHours]);

    useEffect(() => {
        if (shouldFetch) {
            const abortController = new AbortController();
            store.customStore.dispatch(storeActions.fetchStart());
            setLoading(true);

            const responses = [];
            responses.push(loadWorkShifts(abortController.signal));
            responses.push(
                loadWorkHours(showSavedHours, false, abortController.signal)
            );

            responses.push(
                loadDayBookings(abortController.signal)
            );

            Promise.all(responses)
                .then(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }
                })
                .finally(() => {
                    store.customStore.dispatch(storeActions.fetchEnd());
                });

            return () => {
                abortController.abort();
            };
        }
    }, [
        shouldFetch,
        fetchFilters.selectedSalaryPeriod,
        filters.selectedEmployeeGroup?.id,
        reloadKey,
        props.employees,
    ]);

    useFetch(
        () => getDayBookingTypes(),
        {
            onSuccess: (res) =>
                setDayBookingTypes(
                    Base.getTypedArray<DayBookingTypeItemBeta>(
                        res,
                        DayBookingTypeItemBeta
                    )
                ),
        },
        []
    );

    useFetch(
        () => getWorkTimeTypes(),
        {
            onSuccess: (res) => {
                const workTimeTypes = [
                    new WorkTimeType({
                        name: "Ei työvaihetta",
                        workTimeTypeId: "wtt_null",
                        type: 0,
                    }),
                    ...Base.getTypedArray<WorkTimeType>(res, WorkTimeType),
                ];
                setWorkTimeTypes(workTimeTypes);
            },
        },
        []
    );

    useFetch(
        () => getSalaryRowTypeItems(1000, 1, "", "name", true),
        {
            onSuccess: (res) => {
                setSalaryRowTypes(res.items);
            },
        },
        []
    );

    useEffect(() => {
        if (salaryRowTypes && dayBookingTypes) {
            let categoryColumns = WorkTimeListCategoryColumns.flatMap((c) =>
                salaryRowTypes
                    .filter(
                        (t) =>
                            !c.isTotal &&
                            t.hourBookingCategories.includes(c.category) &&
                            !t.hourBookingCategories.includes(
                                c.ignoreIfCategory
                            )
                    )
                    .map(
                        (srt) =>
                            ({
                                id: c.ignoreSalaryRowTypeId
                                    ? c.category.toString()
                                    : srt.id,
                                name: c.label ?? srt.name,
                                measureUnit: srt.measureUnit,
                                decimals: srt.decimals,
                                salaryRowTypeId: c.ignoreSalaryRowTypeId
                                    ? null
                                    : srt.id,
                                hourBookingCategories: srt.hourBookingCategories
                                    .map((cat) => cat.toString())
                                    .filter((cat) =>
                                        (
                                            c.includedCategories ?? [
                                                c.category.toString(),
                                            ]
                                        ).includes(cat)
                                    ),
                                group: c.group,
                            } as WorkTimeListColumn)
                    )
            ).filter(Boolean);

            categoryColumns = [
                ...WorkTimeListCategoryColumns.filter((c) => c.isTotal)
                    .map((c) => {
                        const cols = categoryColumns.filter(
                            (cc) => cc.group === c.group
                        );

                        if (cols.length === 0) {
                            return null;
                        }

                        return {
                            id: `${c.group}-total`,
                            name: c.label.toUpperCase(),
                            measureUnit: cols[0].measureUnit,
                            decimals: cols[0].decimals,
                            group: c.group,
                            isGroupTotal: true,
                            shortName: c.labelShort?.toUpperCase(),
                        } as WorkTimeListColumn;
                    })
                    .filter(Boolean),
                ...categoryColumns,
            ];

            const otherCols = salaryRowTypes
                .filter(
                    (t) =>
                        !categoryColumns.find(
                            (c) =>
                                c.id === t.id ||
                                t.hourBookingCategories
                                    .map((cat) => cat.toString())
                                    .includes(c.id)
                        )
                )
                .map((t) => {
                    const calculateNormal = dayBookingTypes.some(
                        (d) => d.calculateNormal && d.salaryRowTypeId === t.id
                    );
                    return {
                        id: t.id,
                        name: t.name,
                        salaryRowTypeId: t.id,
                        measureUnit: calculateNormal ? StrMeasureUnit.HourFi : t.measureUnit,
                        decimals: t.decimals,
                        hourBookingCategories: calculateNormal
                            ? [HourBookingCategory.DayBookingNormal.toString()]
                            : [],
                        useDayBookingHours: calculateNormal,
                        group: calculateNormal
                            ? WorkTimeListColumnGroup.Paid
                            : getAbsenceSalaryRowTypeKindCodes().includes(
                                t.salaryRowTypeKindCode
                            )
                                ? WorkTimeListColumnGroup.Absence
                                : WorkTimeListColumnGroup.Other,
                    } as WorkTimeListColumn;
                });

            setColumns(
                Base.groupArray([...categoryColumns, ...otherCols], "group")
            );
        }
    }, [salaryRowTypes, dayBookingTypes]);

    // This also locks work times
    function saveHours(salaryPeriod: SalaryPeriodItem) {
        const ownerParameters = new OwnerParameters();
        const employeeIds = filteredEmployees.map((e) => e.id);

        const data = {
            employeeIds,
            salaryPeriodId: salaryPeriod.id,
            workHours:
                workHours
                    ?.filter((wh) => employeeIds.includes(wh.employeeId))
                    .map((w) => getWorkHourSaveItem(w)) ?? [],
            workShiftTimeSlotIds: ownerParameters.getOptionIsFunctionsAreInUse()
                ? []
                : workShifts
                      ?.filter((ws) => employeeIds.includes(ws.employeeId))
                      .flatMap((ws) =>
                          ws.workShiftTimeSlots.map((s) => s.id)
                      ) ?? [],
        } as WorkHourSaveData;

        store.customStore.dispatch(storeActions.fetchStart());

        setSavingSalaries(true);
        workHoursService
            .saveWorkHours(data)
            .then(() => {
                setSaveDialogState("saved");
            })
            .catch((error) => {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(
                        baseService.getErrorMessageFromError(error)
                    )
                );
                return null;
            })
            .finally(() => {
                setSavingSalaries(false);
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    }

    function roundHours(salaryPeriod: SalaryPeriodItem) {
        store.customStore.dispatch(storeActions.fetchStart());

        const promises = [];
        const savePromises = [];
        const ownerParameters = new OwnerParameters();
        const shiftSplitDuration =
            ownerParameters.getWorkShiftSplitDurationMinutes();
        const roundingMinutes = ownerParameters.getWorkShiftRoundingMinutes();
        let inProgressWorkShifts = false;
        for (let i = 0; i < filteredEmployees.length; i++) {
            // get workTimes for employee
            promises.push(
                getWorkShiftTimeSlotItems(
                    Base.dayjsToJsonDateTime(salaryPeriod.startDate),
                    Base.dayjsToJsonDateTime(
                        Base.dateAddDays(salaryPeriod.endDate, 1)
                    ),
                    filteredEmployees[i].id
                ).then((workTimes) => {
                    let shouldLoop = true;
                    const toBeSaved = {};
                    while (shouldLoop) {
                        const roundStart = getDutyStartingWorktimes(
                            workTimes.items,
                            shiftSplitDuration
                        );
                        const startDutys = workTimes.items.filter((i) =>
                            roundStart.includes(i.id)
                        );
                        const dutyEnds = {};

                        // Get endDate for each starting duty.
                        for (const startingWorkTime of startDutys) {
                            const dutySlots = getDutyWorkShiftTimeSlots(
                                startingWorkTime,
                                workTimes.items,
                                shiftSplitDuration
                            ).sort((a, b) =>
                                Base.dayjsCompare(b.endDate, a.endDate)
                            );
                            const endDate = dutySlots[0].endDate;
                            if (endDate) {
                                dutyEnds[startingWorkTime.id] =
                                    Base.dateAddMinutes(
                                        endDate,
                                        shiftSplitDuration
                                    );
                            }
                        }

                        const roundEnd = getDutyEndingWorktimes(
                            workTimes.items,
                            shiftSplitDuration
                        );
                        const firstInProgressWorkTime = workTimes.items
                            .filter((i) => !i.endDate)
                            .sort((a, b) =>
                                Base.dayjsCompare(a.startDate, b.startDate)
                            )[0];
                        inProgressWorkShifts =
                            inProgressWorkShifts || !!firstInProgressWorkTime;
                        let newEdits = false;

                        // loop for every workTime
                        for (let j = 0; j < workTimes.items.length; j++) {
                            if (
                                !workTimes.items[j].endDate ||
                                workTimes.items[j].isLocked()
                            ) {
                                continue;
                            }
                            let edited = false;
                            // Don't round if duty is in progress. (There is in progress work time which starts before duty ends).
                            if (
                                roundStart.includes(workTimes.items[j].id) &&
                                (!firstInProgressWorkTime ||
                                    Base.isBefore(
                                        dutyEnds[
                                            workTimes.items[j].id
                                        ] as Dayjs,
                                        firstInProgressWorkTime.startDate
                                    ))
                            ) {
                                const dateWithoutTz =
                                    Base.dateStrToDayjsIgnoreTimezone(
                                        workTimes.items[j].startDate
                                    ).toDate();
                                const roundedMinutes =
                                    Base.roundDateToNearestMinutes(
                                        dateWithoutTz,
                                        roundingMinutes
                                    );
                                if (
                                    roundedMinutes.getTime() !==
                                    dateWithoutTz.getTime()
                                ) {
                                    workTimes.items[j].startDate =
                                        Base.dateToIsoStringAtTimezone(
                                            roundedMinutes,
                                            Base.tzOffsetFromStr(
                                                workTimes.items[j].startDate
                                            )
                                        );
                                    edited = true;
                                    if (
                                        Base.isAfter(
                                            workTimes.items[j].startDate,
                                            workTimes.items[j].endDate
                                        )
                                    ) {
                                        workTimes.items[j].endDate =
                                            workTimes.items[j].startDate;
                                    }
                                }
                            }
                            // Only round if the work time end (plus getWorkShiftSplitDurationMinutes) is before the earliest in progress work time.
                            if (
                                roundEnd.includes(workTimes.items[j].id) &&
                                (!firstInProgressWorkTime ||
                                    Base.isBefore(
                                        Base.dateAddMinutes(
                                            workTimes.items[j].endDate,
                                            ownerParameters.getWorkShiftSplitDurationMinutes()
                                        ),
                                        firstInProgressWorkTime.startDate
                                    ))
                            ) {
                                const dateWithoutTz =
                                    Base.dateStrToDayjsIgnoreTimezone(
                                        workTimes.items[j].endDate
                                    ).toDate();
                                const roundedMinutes =
                                    Base.roundDateToNearestMinutes(
                                        dateWithoutTz,
                                        roundingMinutes
                                    );
                                if (
                                    roundedMinutes.getTime() !==
                                    dateWithoutTz.getTime()
                                ) {
                                    workTimes.items[j].endDate =
                                        Base.dateToIsoStringAtTimezone(
                                            roundedMinutes,
                                            Base.tzOffsetFromStr(
                                                workTimes.items[j].endDate
                                            )
                                        );
                                    edited = true;
                                    if (
                                        Base.isAfter(
                                            workTimes.items[j].startDate,
                                            workTimes.items[j].endDate
                                        )
                                    ) {
                                        workTimes.items[j].startDate =
                                            workTimes.items[j].endDate;
                                    }
                                }
                            }
                            if (edited) {
                                newEdits = true;
                                toBeSaved[workTimes.items[j].id] =
                                    workTimes.items[j];
                            }
                        }

                        if (!newEdits) {
                            shouldLoop = false;
                        }
                    }
                    savePromises.push(
                        workTimeBetaService.saveWorkShiftTimeSlots(
                            Object.values(toBeSaved)
                        )
                    );
                })
            );
        }
        Promise.all(promises)
            .then(() => {
                Promise.all(savePromises).then(() => {
                    reload();
                });
            })
            .catch((error) => {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(
                        baseService.getErrorMessageFromError(error)
                    )
                );
                return null;
            })
            .finally(() => {
                store.customStore.dispatch(storeActions.fetchEnd());
                if (inProgressWorkShifts) {
                    store.customStore.dispatch(
                        storeActions.showInfoMessage(
                            Translations.WorkTimeRoundingInfoInProgressWorkShifts
                        )
                    );
                }
            });
    }

    const acceptDisabled =
        !workHours?.length || selectedCostCenters?.length > 0 || showSavedHours;

    function acceptVisibleRowsAll() {
        if (!acceptDisabled) {
            setSaveDialogState("open");
        }
    }

    function roundVisibleRowsAll() {
        store.customStore.dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                Translations.RoundUnlockedWorkShiftsApproveWarning,
                () => {
                    store.customStore.dispatch(
                        storeActions.clearConfirmation()
                    );
                    roundHours(fetchFilters.selectedSalaryPeriod);
                },
                () => {
                    store.customStore.dispatch(
                        storeActions.clearConfirmation()
                    );
                }
            )
        );
    }

    function toggleLock(
        workTimeListItems: WorkShiftTimeSlotItem[],
        dayBookingItems: DayBookingItemBeta[],
        lock: boolean
    ) {
        store.customStore.dispatch(storeActions.fetchStart());
        const workTimeIds = workTimeListItems.map((x) => x.id);
        const dayBookingIds = dayBookingItems.map((x) => x.id);
        const promises = [];

        if (workTimeIds.length > 0) {
            promises.push(
                lock
                    ? workTimeService.lockWorkTimes(workTimeIds)
                    : workTimeService.unlockWorkTimes(workTimeIds)
            );
        }

        if (dayBookingIds.length > 0) {
            promises.push(
                lock
                    ? dayBookingService.lockDayBookings(dayBookingIds)
                    : dayBookingService.unLockDayBookings(dayBookingIds)
            );
        }

        Promise.all(promises)
            .then(async() => {
                if (workTimeIds.length > 0) await loadWorkShifts();
                if (dayBookingIds.length > 0) await loadDayBookings();
            })
            .catch((error) => {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(
                        baseService.getErrorMessageFromError(error)
                    )
                );
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    }

    const initialized =
        !loading &&
        workTimeTypes &&
        workShifts &&
        dayBookingItems &&
        workHours &&
        columns &&
        shouldFetch;

    return (
        <div className="workTimeList" style={{ padding: "1rem" }}>
            {loading && (
                <div className="loading">{Translations.Loading}...</div>
            )}
            {initialized && (
                <Grid2 container justifyContent={"space-between"}>
                    <Grid2>
                        <Typography variant="h3">
                            {Translations.WorkTimeApproval}
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <MuiRadioGroup
                            options={Object.keys(DisplayState).map((k) => ({
                                value: DisplayState[k],
                                label: t(
                                    `workTime.workTimeListDisplayState.${k}`
                                ),
                            }))}
                            value={displayState}
                            onChange={(val) => setDisplayState(val)}
                            row
                        />
                    </Grid2>
                    <Grid2
                        xs={12}
                        justifyContent={"space-between"}
                        container
                        spacing={2}
                    >
                        <Grid2
                            xs={12}
                            sx={{ display: "flex", maxHeight: "60vh" }}
                            id={"workTimeListContainer"}
                        >
                            <WorkTimeList
                                employees={props.employees ?? []}
                                employeeIds={employeeIds}
                                selectedCostCenterIds={selectedCostCenters}
                                groupedColumns={columns}
                                workTimeTypes={workTimeTypes}
                                workShifts={workShifts}
                                dayBookings={dayBookingItems}
                                workHours={workHours}
                                salaryPeriod={fetchFilters.selectedSalaryPeriod}
                                path={props.path}
                                lockRows={(items, dayBookings) =>
                                    toggleLock(items, dayBookings, true)
                                }
                                unlockRows={(items, dayBookings) =>
                                    toggleLock(items, dayBookings, false)
                                }
                            />
                        </Grid2>

                        <Grid2 xs={12} container spacing={2}>
                            <WorkTimeBottomBar
                                acceptAllButtonPress={() => {
                                    acceptVisibleRowsAll();
                                }}
                                acceptDisabled={acceptDisabled}
                                roundAllButtonPress={() => {
                                    roundVisibleRowsAll();
                                }}
                                costCenterFilterActive={
                                    selectedCostCenters?.length > 0
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            )}

            <Dialog
                open={saveDialogState !== "closed"}
                onClose={() => setSaveDialogState("closed")}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{t("workTime.saveSalariesTitle")}</DialogTitle>
                {saveDialogState === "open" && (
                    <>
                        <DialogContent>
                            <p>{t("workTime.workHoursApproveConfirmation")}</p>
                            <Alert severity="info">
                                {t("workTime.workHoursApproveWarning")}
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={savingSalaries}
                                variant="primary"
                                onClick={() => setSaveDialogState("closed")}
                            >
                                {Translations.Cancel}
                            </Button>
                            <Button
                                disabled={savingSalaries}
                                variant="primary"
                                onClick={() =>
                                    saveHours(fetchFilters.selectedSalaryPeriod)
                                }
                            >
                                {t("workTime.createSalaries")}
                            </Button>
                        </DialogActions>
                    </>
                )}
                {saveDialogState === "saved" && (
                    <>
                        <DialogContent>
                            <Alert severity="success">
                                <AlertTitle>
                                    {t("workTime.saveSalariesSuccessTitle")}
                                </AlertTitle>
                                {t("workTime.saveSalariesSuccessMessage")}
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    customHistory.push(
                                        "/worktimepage/salaries/"
                                    )
                                }
                            >
                                {t("workTime.goToSalaries")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setSaveDialogState("closed");
                                    reload();
                                }}
                            >
                                {Translations.Close}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}
