import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as tokenService from "../../services/tokenService";
import * as acknowledgementService from "../../services/acknowledgementService";
import * as employeeService from "../../services/employeeService";
import * as workOrderService from "../../services/workOrderService";
import * as storeTypes from "./storeTypes";
import * as storeActions from "./storeActions";
import { customHistory } from "../../framework/customHistory";
import { isInstanceOfIApiError } from "../../services/baseService";
import { CalendarAccuracyType } from "../common/enums";
import { IEmployeeParameters } from "../employee/employeeIParameters";
import { IRoutePointAutocompleteItems } from "../routePoint/routePointAutocompleteItems";
import { clearStore } from "../../store/appSlice";
import { sessionSlice, setUserId } from "../../store/sessionSlice";

/**
 * IMPORTANT: This is legacy code. Define new stores using @reduxjs/toolkit (see: timelinePlannerSlice.ts).
 */

export const handleApiError = (error: any, dispatch: ThunkDispatch<storeTypes.IApplicationState, any, any>) => {
    console.log(error);
    if (error.name === "AbortError") return;
    if (isInstanceOfIApiError(error)) {
        if (error.status === 401) {
            customHistory.push("/login");
        }
        dispatch(storeActions.showErrorMessage(error.message));
    } else {
        dispatch(storeActions.showErrorMessage(error));
    }
};

// Token
export type LogonThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.LoginAction>

export const login = (username: string, password: string): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    dispatch(storeActions.fetchStart());
    return tokenService.login(username, password)
        .then(
            sessionState => {
                if (getState()[sessionSlice.name].userId !== sessionState.id) {
                    // Clear store if userId is different than existing.
                    dispatch(clearStore());
                }
                dispatch(setUserId(sessionState.id));
                dispatch(
                    storeActions.setOwnerState(
                        sessionState.ownerName,
                        sessionState.ownerName2,
                        sessionState.ownerParameters
                    )
                );
                dispatch(
                    storeActions.setUserState(
                        sessionState.id,
                        sessionState.isOwnerAdmin,
                        sessionState.ssoIntegrations
                    )
                );
                dispatch(storeActions.loginSuccess());
                customHistory.push("/");
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.loginFailure());
            })
        .finally(() => dispatch(storeActions.fetchEnd()));
};

export const logout = (): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    tokenService.logout()
        .then(() => {
            dispatch(storeActions.logoutSuccess());
            dispatch(storeActions.ownerInitialize());
            dispatch(storeActions.acknowledgementInitialize());
            dispatch(storeActions.workMainInitialize());
            dispatch(storeActions.ownerRoutePointsInitialize());
            customHistory.push("/login");
        });
};

// Acknowledgement
export type AcknowledgementThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.AcknowledgementAction>

export const getAcknowledgementMain = (id: string): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    dispatch(storeActions.fetchStart());
    dispatch(storeActions.acknowledgementLoadStart());
    return acknowledgementService.getAcknowledgementMain(id)
        .then(
            acknowledgementMain => {
                dispatch(storeActions.acknowledgementMainSuccess(acknowledgementMain));
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.acknowledgementMainFailure());
            })
        .finally(() => dispatch(storeActions.fetchEnd()));
};

// Work
export type WorkThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.WorkAction>

export const getWorkOrderMain = (): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    //dispatch(storeActions.fetchStart()); removing global loading indicator
    return workOrderService.getWorkOrderMain()
        .then(
            workMain => {
                dispatch(storeActions.setUserParameters(workMain.employeeParameters));
                dispatch(storeActions.workMainSuccess(workMain.workOrders, workMain.workOrderTemplates, workMain.favoriteWorkOrders, workMain.showOnMapVehicles,
                    workMain.calendarLines, workMain.calendarDays, workMain.dayBookings, workMain.dayBookingTypes, workMain.workOrderStates, workMain.workOrderClasses,
                    workMain.employeeGroups, workMain.vehicleGroups, workMain.startDate, workMain.endDate, workMain.workTimeFormat));
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.workMainFailure());
            });
    //.finally(() => dispatch(storeActions.fetchEnd())); removing global loading indicator. Do not add this back
};

export const getWorkOrderItems = (startDate: number, endDate: number, calendarAccuracy: CalendarAccuracyType): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    dispatch(storeActions.fetchStart());
    return workOrderService.getWorkOrderItems(startDate, endDate, calendarAccuracy)
        .then(
            workItems => {
                dispatch(storeActions.workItemsSuccess(workItems.workOrders, workItems.workOrderTemplates, workItems.favoriteWorkOrders, workItems.showOnMapVehicles,
                    workItems.calendarLines, workItems.calendarDays, workItems.dayBookings, workItems.dayBookingTypes, workItems.startDate, workItems.endDate));
            },
            error => {
                if (window.location.href.indexOf("/login") === -1) {
                    handleApiError(error, dispatch);
                    dispatch(storeActions.workItemsFailure());
                }
            })
        .finally(() => dispatch(storeActions.fetchEnd()));
};

export const getWorkOrderTemplates = (): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    dispatch(storeActions.fetchStart());
    return workOrderService.getWorkOrderTemplates()
        .then(
            workOrderTemplates => {
                dispatch(storeActions.workOrderTemplatesSuccess(workOrderTemplates.items));
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.workItemsFailure());
            })
        .finally(() => dispatch(storeActions.fetchEnd()));
};

// Route point
export type OwnerRoutePointsThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.OwnerRoutePointsAction>

export const getOwnerRoutePoints = (): ThunkAction<any, storeTypes.IApplicationState, any, any> => dispatch => {
    dispatch(storeActions.fetchStart());
    return workOrderService.getOwnerRoutePoints()
        .then(
            (routePoints: IRoutePointAutocompleteItems) => dispatch(storeActions.getOwnerRoutePointsSuccess(routePoints)),
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.getOwnerRoutePointsFailure());
            }
        )
        .finally(() => dispatch(storeActions.fetchEnd()));
};

// Employee
export const getEmployeeParameters = (): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    //dispatch(storeActions.fetchStart());
    return employeeService.getEmployeeParameters()
        .then(
            parameters => {
                dispatch(storeActions.setUserParameters(parameters || {}));
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(storeActions.workMainFailure());
            });
    //.finally(() => dispatch(storeActions.fetchEnd()));
};

export const setEmployeeParameters = (parameters: IEmployeeParameters, saveToDb: boolean): ThunkAction<any, storeTypes.IApplicationState, any, any> => (dispatch, getState) => {
    if (saveToDb) {
        //dispatch(storeActions.fetchStart());
        return employeeService.setEmployeeParameters(parameters)
            .then(
                success => {
                    dispatch(storeActions.setUserParameters(parameters));
                },
                error => {
                    handleApiError(error, dispatch);
                    dispatch(storeActions.workMainFailure());
                });
        //.finally(() => dispatch(storeActions.fetchEnd()));
    } else {
        dispatch(storeActions.setUserParameters(parameters));
    }
};

// Settings
export type SettingsThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.SettingsAction>

// Tracking
export type TrackingThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.TrackingAction>

//Storage
export type StorageThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.TrackingAction>

//Reporting
export type ReportingThunkDispatch = ThunkDispatch<storeTypes.IApplicationState, null, storeTypes.ReportingAction>

