import { Box, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import {
    hourBookingTypeCategoryFullDayAllowance,
    hourBookingTypeCategoryHalfDayAllowance,
} from "../../models/common/consts";
import { DayBookingUsageType } from "../../models/common/enums";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";
import { showApiError, showApiSuccess } from "../framework/formUtils";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import MuiRadioGroup, {
    IMuiRadioGroupOption,
} from "../framework/muiRadioGroup";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { deleteDayBooking, saveDayBooking } from "../../services/dayBookingService";
import { DayBookingSaveData } from "../../models/dayBooking/dayBookingSaveData";
import { useSelectDailyAllowancesByDate } from "../../hooks/workTime/useSelectWorkHours";
import { deleteCategoryFromWorkShiftTimeSlot } from "../../services/workTimeBetaService";

const createSaveFormData = (
    newTypeId: string,
    employeeId: string,
    date: string
): DayBookingSaveData => ({
    id: "",
    employeeId,
    dayBookingTypeId: newTypeId,
    day: Base.dayjsToJsonDate(date),
    amount: 1,
    dayUsageType: DayBookingUsageType.NotFullDay,
    comment: "",
    rowId: "",
});

interface IDayAllowanceSelectProps {
    reloadList: () => void;
    dayBookingTypes: DayBookingTypeItemBeta[];
    dayBookingItems: DayBookingItemBeta[];
    allWorkTimesLocked: boolean;
    loadingDayBookings: boolean;
}

export const DayAllowanceSelect = ({
    reloadList,
    dayBookingTypes,
    dayBookingItems,
    allWorkTimesLocked,
    loadingDayBookings,
}: IDayAllowanceSelectProps) => {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();

    const relevantTypes = useMemo(
        () =>
            dayBookingTypes?.filter(
                (type) =>
                    type.category === hourBookingTypeCategoryHalfDayAllowance ||
                    type.category === hourBookingTypeCategoryFullDayAllowance
            ),
        [dayBookingTypes]
    );

    const existingAllowances: DayBookingItemBeta[] = useMemo(
        () =>
            dayBookingItems?.filter((item) =>
                relevantTypes?.some((type) => type.id === item.dayBookingTypeId)
            ) || [],
        [dayBookingItems, relevantTypes]
    );

    const existingAllowance =
        existingAllowances.length === 1 ? existingAllowances[0] : null;

    const options: IMuiRadioGroupOption[] = relevantTypes?.map((type) => ({
        label: type.name,
        value: type.id,
    }));
    options?.unshift({
        label: AppUtils.translate("dayAllowance.noAllowance"),
        value: "",
    });

    const calcAllowances = useSelectDailyAllowancesByDate(
        employeeId,
        date,
        date
    );
    const calcAllowance =
        calcAllowances.length === 1 ? calcAllowances[0] : null;
    const calcDayBookingTypeId = relevantTypes.find(
        (t) => t.category.toString() === calcAllowances[0]?.category
    )?.id;

    const handleDayAllowanceChange = async (newTypeId) => {
        let isDeleteOperationPerformed = false;
        let isCreateOperationPerformed = false;

        if (existingAllowance) {
            try {
                await deleteDayBooking(existingAllowance.id);
                isDeleteOperationPerformed = true;
            } catch (error) {
                showApiError(AppUtils.translate("dayAllowance.deleteFail"));
                return;
            }
        }

        if (calcAllowance) {
            try {
                await deleteCategoryFromWorkShiftTimeSlot(
                    calcAllowance.workShiftTimeSlotId,
                    calcAllowance.category,
                    false
                );
                isDeleteOperationPerformed = true;
            } catch (error) {
                showApiError(AppUtils.translate("dayAllowance.deleteFail"));
                return;
            }
        }

        if (newTypeId && newTypeId !== "") {
            const saveFormData = createSaveFormData(
                newTypeId as string,
                employeeId,
                date
            );
            try {
                await saveDayBooking(saveFormData);
                isCreateOperationPerformed = true;
            } catch (error) {
                showApiError(AppUtils.translate("dayAllowance.createFail"));
                return;
            }
        }

        if (isDeleteOperationPerformed && isCreateOperationPerformed) {
            showApiSuccess(AppUtils.translate("dayAllowance.updateSuccess"));
        } else if (isDeleteOperationPerformed) {
            showApiSuccess(AppUtils.translate("dayAllowance.deleteSuccess"));
        } else if (isCreateOperationPerformed) {
            showApiSuccess(AppUtils.translate("dayAllowance.createSuccess"));
        }

        handleAfterSaveOrRemove();
    };

    const handleAfterSaveOrRemove = () => {
        reloadList();
    };

    if (
        relevantTypes.length === 0 ||
        existingAllowances.length + calcAllowances.length > 1
    )
        return null;

    if (loadingDayBookings)
        return (
            <Box display="flex" justifyContent="flex-end" height={42}>
                <LoadingIndicator />
            </Box>
        );

    const disabled = allWorkTimesLocked;
    const dayBookinTypeId =
        existingAllowance?.dayBookingTypeId ?? calcDayBookingTypeId ?? "";

    return (
        <Box display="flex" justifyContent="flex-end">
            {disabled ? (
                <Tooltip
                    title={AppUtils.translate("dayAllowance.editForbidden")}
                >
                    <Box>
                        <MuiRadioGroup
                            options={options}
                            value={dayBookinTypeId}
                            row
                            disabled
                        />
                    </Box>
                </Tooltip>
            ) : (
                <MuiRadioGroup
                    options={options}
                    onChange={(val) => void handleDayAllowanceChange(val)}
                    value={dayBookinTypeId}
                    row
                />
            )}
        </Box>
    );
};
